define("lh4/templates/authenticated/launch-control/discounts/landing-page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ETYFJmqp",
    "block": "{\"symbols\":[\"modal\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"s4-launch-control-first-page-container\"],[8],[0,\"\\n\\t\"],[5,\"ui/launch-control/fullscreen-modal\",[],[[\"@title\",\"@onClose\",\"@isWhiteHeader\",\"@isBlackCloseHeader\",\"@isBlackCloseButton\",\"@isLaunchControl\",\"@firstStep\",\"@isLoading\",\"@showFlashMessage\"],[\"title\",[23,0,[\"fullscreenModalClose\"]],true,true,true,true,0,[23,0,[\"isLoading\"]],true]],{\"statements\":[[0,\"\\n\\t\\t\"],[6,[23,1,[\"content\"]],[],[[],[]],{\"statements\":[[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"landing-page\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"container\"],[8],[0,\"\\n\\t\\t\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"column\"],[8],[0,\"\\n\\t\\t\\t\\t\\t\\t\"],[7,\"img\",true],[10,\"src\",\"/img/launch-control/discounts.svg\"],[10,\"alt\",\"Discounts\"],[8],[9],[0,\"\\n\\t\\t\\t\\t\\t\\t\"],[7,\"h1\",true],[8],[0,\"Discounts and Gratuities\"],[9],[0,\"\\n\\t\\t\\t\\t\\t\\t\"],[7,\"p\",true],[8],[0,\"Now let’s setup your discounts and gratuities.\"],[9],[0,\"\\n\\t\\t\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"button-section\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[5,\"ui/s4/buttons/button\",[],[[\"@type\",\"@onClick\",\"@text\"],[\"rounded-outline\",[23,0,[\"skip\"]],\"SETUP LATER\"]]],[0,\"\\n\\t\\t\\t\\t\"],[5,\"ui/s4/buttons/button\",[],[[\"@type\",\"@text\",\"@onClick\"],[\"rounded-contained\",\"GET STARTED\",[23,0,[\"start\"]]]]],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\t\\n\\t\\t\"]],\"parameters\":[]}],[0,\"\\n\\t\"]],\"parameters\":[1]}],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/authenticated/launch-control/discounts/landing-page.hbs"
    }
  });

  _exports.default = _default;
});