define("lh4/templates/authenticated/launch-control/progress", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TJE4M2PL",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"progress\"],[10,\"data-dd-action-name\",\"Progress\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",false],[12,\"class\",\"title\"],[3,\"did-insert\",[[23,0,[\"shootConfetti\"]]]],[8],[0,\"\\n\\t\\t\\t\"],[1,[24,[\"model\",\"headerText\"]],false],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[1,[23,1,[\"subText\"]],false],[0,\"\\n\\n\\t\\t\"],[5,\"ui/s4/loaders/s4-progress\",[],[[\"@progressPercentage\",\"@progressOffset\"],[[24,[\"model\",\"progressPercentage\"]],[24,[\"model\",\"progressOffset\"]]]]],[0,\"\\n\\n\\t\\t\"],[5,\"ui/s4/buttons/button\",[],[[\"@type\",\"@text\",\"@onClick\",\"@data-dd-action-name\"],[\"contained\",\"DONE\",[23,0,[\"exitProgress\"]],\"Done\"]]],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/authenticated/launch-control/progress.hbs"
    }
  });

  _exports.default = _default;
});