define("lh4/templates/components/tables/customer-import-table/row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8FUl1emX",
    "block": "{\"symbols\":[\"attribute\",\"@isSelected\",\"@parsedCustomer\",\"@toggleSelection\"],\"statements\":[[7,\"tr\",true],[11,\"class\",[29,[[28,\"if\",[[23,3,[\"isInvalid\"]],\" negative\"],null]]]],[8],[0,\"\\n\\t\"],[7,\"td\",true],[8],[0,\"\\n\"],[4,\"if\",[[23,3,[\"isInvalid\"]]],null,{\"statements\":[[4,\"ui-popup\",null,[[\"html\"],[[28,\"ul\",[[23,3,[\"validationsMessages\"]]],null]]],{\"statements\":[[0,\"\\t\\t\\t\\t\"],[7,\"i\",true],[10,\"class\",\"red warning sign icon\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"\\t\\t\\t\"],[1,[28,\"ui/checkbox\",null,[[\"onChange\",\"checked\",\"class\"],[[23,4,[]],[23,2,[]],\"multi-table-checkbox\"]]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\t\"],[9],[0,\"\\n\"],[4,\"each\",[[23,3,[\"attributes\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"td\",true],[11,\"class\",[29,[[28,\"if\",[[28,\"and\",[[23,3,[\"isValid\"]],[28,\"not\",[[23,2,[]]],null]],null],\"disabled\"],null],\" \",[28,\"if\",[[23,1,[\"isValid\"]],\"black cell\",\"red cell\"],null]]]],[8],[0,\"\\n\\t\\t\\t\"],[1,[23,1,[\"value\"]],false],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/tables/customer-import-table/row.hbs"
    }
  });

  _exports.default = _default;
});