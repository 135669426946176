define("lh4/templates/components/ui/hidden-items-info-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yrj5qJ2Y",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,0,[\"inactiveItemsWarning\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[10,\"class\",\"ui info message\"],[8],[0,\"\\n\\t\\t\"],[7,\"i\",true],[10,\"class\",\"icon info circle\"],[8],[9],[0,\"Inactive \"],[1,[23,0,[\"entityName\"]],false],[0,\" match the search.  Turn off ‘Hide Inactive’ to see them\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/hidden-items-info-message.hbs"
    }
  });

  _exports.default = _default;
});