define("lh4/templates/components/ui/navigation/vertical-navigation-controls/location-selection", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SFdclZ0w",
    "block": "{\"symbols\":[\"@accountStatus\",\"@locationStatus\",\"@changeLocation\",\"@location\"],\"statements\":[[7,\"div\",false],[12,\"class\",\"location-selection\"],[12,\"role\",\"button\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],[23,3,[]]]],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"location-wrapper\"],[8],[0,\"\\n\\t\\t\"],[7,\"span\",true],[10,\"class\",\"name\"],[8],[1,[23,4,[\"model\",\"name\"]],false],[9],[0,\"\\n\\t\\t\"],[7,\"span\",true],[10,\"class\",\"merchant\"],[8],[1,[23,4,[\"model\",\"merchantId\"]],false],[9],[0,\"\\n\"],[4,\"if\",[[28,\"can\",[\"see timezone in general\"],null]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"span\",true],[10,\"class\",\"merchant\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[1,[22,\"formattedTime\"],false],[1,[28,\"timezone-abbr\",[[24,[\"location\",\"model\",\"timezone\"]]],null],false],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[28,\"or\",[[28,\"eq\",[[23,1,[]],\"PENDING\"],null],[28,\"eq\",[[23,1,[]],\"BLOCKED\"],null]],null]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"badge\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[7,\"div\",true],[11,\"class\",[29,[\"status \",[28,\"if\",[[28,\"eq\",[[23,1,[]],\"BLOCKED\"],null],\"error\"],null]]]],[8],[9],[0,\"\\n\\t\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"status-text\"],[8],[1,[23,2,[]],false],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\t\"],[9],[0,\"\\n\"],[4,\"if\",[[23,4,[\"hasMultipleLocations\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"i\",true],[10,\"class\",\"icon chevron down circular-button secondary\"],[10,\"alt\",\"Change Location Icon\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/navigation/vertical-navigation-controls/location-selection.hbs"
    }
  });

  _exports.default = _default;
});