define("lh4/templates/components/updates/deploy-create-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "du6NwNXl",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h2\",true],[10,\"class\",\"ui header toggle-header\"],[8],[0,\"\\n\\tDeployment Details\\n\"],[9],[0,\"\\n\"],[1,[28,\"ui/form-fields/input-field\",null,[[\"changeset\",\"label\",\"property\",\"disabled\",\"showErrors\"],[[24,[\"changeset\"]],\"Deployment Name\",\"name\",true,[24,[\"showErrors\"]]]]],false],[0,\"\\n\"],[1,[28,\"ui/form-fields/input-field\",null,[[\"changeset\",\"property\",\"label\",\"showErrors\"],[[24,[\"changeset\"]],\"notes\",\"Notes\",[24,[\"showErrors\"]]]]],false],[0,\"\\n\"],[7,\"h2\",true],[10,\"class\",\"ui header toggle-header\"],[8],[0,\"\\n\\tDeployment Date\\n\"],[9],[0,\"\\n\"],[1,[28,\"ui/form-fields/date-field\",null,[[\"changeset\",\"property\",\"arrowOn\",\"fullWidth\",\"label\",\"saveAsISOString\",\"showErrors\",\"allowNull\",\"disabled\"],[[24,[\"changeset\"]],\"scheduledFor\",\"right\",false,\"Date\",true,[24,[\"showErrors\"]],true,true]]],false],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"checkbox-container apply-immediately\"],[8],[0,\"\\n\\t\"],[1,[28,\"ui-checkbox\",null,[[\"class\",\"label\",\"checked\",\"onChange\",\"disabled\"],[\"vertical-checkbox\",\"Apply Immediately\",[24,[\"changeset\",\"instant\"]],[24,[\"onChange\"]],true]]],false],[0,\"\\n\\n\"],[9],[0,\"\\n\"],[7,\"h2\",true],[10,\"class\",\"ui header toggle-header overview-header\"],[8],[0,\"\\n\\tLocation Overview\\n\"],[9],[0,\"\\n\"],[1,[28,\"updates/location-deploy-table\",null,[[\"changeset\",\"rows\"],[[24,[\"changeset\"]],[24,[\"locations\"]]]]],false],[0,\"\\n\"],[7,\"p\",true],[8],[0,\"If you want make changes first make them in \"],[7,\"a\",false],[12,\"href\",\"#\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"redirectToLocationSettings\"]],[8],[0,\"Location Settings\"],[9],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/updates/deploy-create-form.hbs"
    }
  });

  _exports.default = _default;
});