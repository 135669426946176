define("lh4/services/external-permissions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var externalPermissionType = Object.freeze({
    ANALYTICS_LINK_VISIBILITY: 'analytics-link-visibility'
  });

  var _default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    tokenData: Ember.inject.service(),
    types: externalPermissionType,
    loading: {},
    setLoading: function setLoading(type, value) {
      this.set('loading', _objectSpread(_objectSpread({}, this.loading), {}, _defineProperty({}, type, value)));
    },
    permissions: {},
    setPermission: function setPermission(type, value) {
      this.set('permissions', _objectSpread(_objectSpread({}, this.permissions), {}, _defineProperty({}, type, value)));
    },
    getPermission: function getPermission(type) {
      switch (type) {
        case this.types.ANALYTICS_LINK_VISIBILITY:
          // get cached permissions
          if (typeof this.permissions[type] !== 'undefined') {
            return this.permissions[type];
          } // get visibility from api then cache the result


          if (!this.loading[type]) {
            this.setLoading(type, true); // 	this.ajax.request('/api/v1/analytics/permissions', { type: 'GET' })
            // 		.then((response) => {
            // 			this.setLoading(type, false);
            // 			if (!response || !response.enabled) {
            // 				return this.setPermission(type, false);
            // 			}
            // 			return this.setPermission(type, response.enabled);
            // 		})
            // 		.catch(() => {
            // 			this.setLoading(type, false);
            // 			this.setPermission(type, false);
            // 		});
          } // default to false while we wait for api call to resolve


          return false;

        default:
          return false;
      }
    }
  });

  _exports.default = _default;
});