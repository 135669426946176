define("lh4/templates/components/ui/modal/modal-dialog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oVS53oJI",
    "block": "{\"symbols\":[\"@data-dd-action-name\",\"@title\",\"@onClose\",\"&default\",\"@class\",\"@isOpen\"],\"statements\":[[4,\"if\",[[23,6,[]]],null,{\"statements\":[[4,\"modal/route-modal\",null,[[\"class\"],[[28,\"concat\",[\"s4-modal-dialog tiny modal-dialog \",[23,5,[]]],null]]],{\"statements\":[[0,\"\\t\\t\"],[7,\"div\",true],[10,\"class\",\"dialog\"],[11,\"data-dd-action-name\",[23,1,[]]],[8],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"drawer\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"top-bar\"],[8],[0,\"\\n\\t\\t\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"top\"],[8],[0,\"\\n\\t\\t\\t\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"header\"],[8],[0,\"\\n\\t\\t\\t\\t\\t\\t\\t\"],[7,\"span\",true],[10,\"class\",\"text\"],[8],[0,\"\\n\\t\\t\\t\\t\\t\\t\\t\\t\"],[1,[23,2,[]],false],[0,\"\\n\\t\\t\\t\\t\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\\t\\t\\t\"],[7,\"div\",false],[12,\"class\",\"circular-button secondary\"],[12,\"data-dd-action-name\",\"Exit\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],[23,3,[]]]],[8],[0,\"\\n\\t\\t\\t\\t\\t\\t\\t\"],[5,\"ui/icons/s4/close\",[],[[],[]]],[0,\"\\n\\t\\t\\t\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"divider\"],[8],[9],[0,\"\\n\\t\\t\\t\"],[14,4,[[28,\"hash\",null,[[\"body\"],[[28,\"component\",[\"ui/shared/yield-basic\"],[[\"defaultClassNames\"],[\"body\"]]]]]]]],[0,\"\\n\\t\\t\\t\"],[14,4,[[28,\"hash\",null,[[\"actions\"],[[28,\"component\",[\"ui/shared/yield-basic\"],[[\"defaultClassNames\"],[\"footer\"]]]]]]]],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/modal/modal-dialog.hbs"
    }
  });

  _exports.default = _default;
});